@mixin button {
  &__options {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;

    @include break('small') {
      flex-direction: column;
    }
  }

  &__button {
    padding: 1em 1.25em;
    margin: 0.5em;
    border-radius: 2px;
    border: none;
    background-color: $greenish;
    font-size: 115%;
    font-weight: 400;
    letter-spacing: 1px;
    text-decoration: none;
    color: $white;

    &:hover {
      background-color: $darkish-green;
    }

    @include break('small') {
      text-align: center;
    }
  }
}