html {
  width: auto;
}

body {
  font-family: $base-font-family;
  font-size: $base-font-size;
  font-weight: $base-font-weight;
  line-height: $base-line-height;
  color: $brownish-grey;
  margin: 0;
  padding: 0;

  @include break('small') {
    font-size: 80%;
  }
}

.anchor {
  content: ' ';
  display: block;
  padding-top: 5.5em;
  margin-top: -5.5em;
  visibility: hidden;

  @include break('small') {
    padding-top: 4.5em;
    margin-top: -4.5em;
  }
}