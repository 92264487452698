@import 'elements/content';

.advertisers {
  @include content;
  background-color: $pale-grey;

  p {
    width: 100%;
  }

  hr {
    width: 100%;
    height: 1px;
    margin: 3em 0;
    background-color: $brownish-grey-25;
    border: 0;
  }

  h3 {
    font-size: 175%;
    font-weight: 600;
    text-transform: uppercase;
  }

  a {
    color: $deep-sea-blue;
    font-weight: 600;

    &:hover {
      color: $perrywinkle;
    }
  }

  &--checklist {
    width: 100%;
    list-style: none;
    margin: 0 1em;

    li {
      padding: 0 1em;
      line-height: 2em;
    }
  }

  &__logos {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;

    & img {
      margin: 1em;

      &.advertisers__logos--vertical {
        max-height: 50px;
      }

      &.advertisers__logos--horizontal {
        max-width: 175px;
      }

      &.advertisers__logos--square {
        max-height: 100px;
      }
    }
  }
}