@import 'elements/content';

.privacy {
  @include content;
  padding-top: 10em;

  h3 {
    font-size: 100%;
    align-self: flex-start;
    margin: 1em 0 0;
  }

  h4 {
    font-size: 100%;
    align-self: flex-start;
    text-decoration: underline;
    font-weight: 400;
    margin: 1em 0 0;
  }

  p {
    width: 100%;
    text-align: justify;
    margin-top: 0.5em;
  }

  ul, ol {
    align-self: flex-start;
    margin: 0.5em 0;
    max-width: 100%;
  }

  a {
    color: $deep-sea-blue;

    &:hover {
      color: $perrywinkle;
    }
  }

  @include break('small') {
    padding-top: 9em;
  }

  &__dateline {
    align-self: flex-start;
    font-style: italic;
    font-size: 80%;
  }

  &__address {
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    padding-left: 3em;
    margin-bottom: 2em;
  }
}
