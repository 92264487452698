@import 'elements/content';
@import 'elements/button';

.publishers {
  @include content;

  &__container {
    width: 100%;

    @include break('x-small') {
      display: flex;
      flex: 0 1 auto;
      flex-direction: column;
      align-items: center;

      p {
        width: 100%;
      }
    }
  }

  &--checklist {
    align-self: flex-start;
    list-style: none;

    li {
      line-height: 2em;

      &:before {
        font-family: $icon-font;
        content: '\f00c';
        margin: 0 0.5em 0 -1.5em;
        color: $perrywinkle;
        font-size: 1.25em;
      }
    }

    @include break('x-small') {
      width: calc(100% - 2.55em);
    }
  }

  &__image--left {
    float: left;
    margin: 0 1em 1em 0;

    @include break('small') {
      margin: 1em;
    }

    @include break('x-small') {
      float: none;
      width: 100%;
    }
  }

  &__image--right {
    float: right;
    margin: 0 0 1em 1em;

    @include break('small') {
      margin: 1em;
    }

    @include break('x-small') {
      float: none;
      width: 100%;
    }
  }

  @include button;

  &__button i {
    font-weight: 600;
    font-size: 90%;
  }

  @import 'publishers_sections';
}