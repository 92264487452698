// Colors
$perrywinkle: #76aadd;
$perrywinkle-50: rgba($perrywinkle, 0.5);
$deep-sea-blue: #034f76;
$deep-sea-blue-25: rgba($deep-sea-blue, 0.25);
$brownish-grey: #666666;
$brownish-grey-25: rgba($brownish-grey, 0.25);
$white: #ffffff;
$white-50: rgba($white, 0.5);
$greyish-brown: #444444;
$greenish: #439e5b;
$darkish-green: #357e48;
$pale-grey: #e0e6ed;

// Fonts
@import url('//fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800');
@import url('//maxcdn.bootstrapcdn.com/font-awesome/4.6.3/css/font-awesome.min.css');

$icon-font: 'FontAwesome';

$base-font-family: 'Open Sans', sans-serif;
$base-font-weight: 400;
$base-font-size: 16px;
$base-line-height: 25.6px;

// Images
$hero-image: url('/img/main-bg@3x.png');

// Breakpoints
$breakpoints: (
  'x-small': ( max-width: 420px ),
  'small':   ( max-width: 770px ),
  'medium':  ( max-width: 1028px ),
  'large':   ( max-width: 1200px )
);