.navbar li {
  list-style-type: none;

  a {
    color: $white;
    text-decoration: none;

    &:hover,
    &.navbar__link--active {
      color: $perrywinkle;
    }
  }
}

.header__container {
  align-content: center;

  .navbar__toggle {
    display: none;

    @include break('medium') {
      display: flex;
      flex: 0 0 auto;
      cursor: pointer;
      justify-content: center;
      align-self: center;
      padding: 0.7em;
      background-color: transparent;
      border-width: 1px;
      border-style: solid;
      border-radius: 4px;
      font-size: 1em;
      color: $white;
      text-align: center;
    }
  }
}

.header__navbar {
  display: flex;
  justify-content: flex-end;
  padding-right: 3.55em;
  width: 100%;

  .navbar__menu {
    display: flex;
    flex: 0 0 auto;
    flex-direction: row;
    justify-content: space-between;
    margin: 0;
    padding: 0;

    li {
      display: block;
      margin-left: 2em;

      @include break('medium') {
        width: 100%;
        margin: 0;
      }

      a {
        font-size: 112%;
        font-weight: 400;
        text-transform: uppercase;

        &.navbar__link--highlight {
          text-shadow: 0 0 7px $white-50;
        }

        &:hover.navbar__link--highlight {
          text-shadow: 0 0 5px $perrywinkle-50;
        }

        @include break('medium') {
          display: block;
          padding: 1em 3.55em;
          width: calc(100% - 7.1em);
          color: $brownish-grey;

          &.navbar__link--highlight {
            text-shadow: 0 0 7px $perrywinkle-50;
          }

          &:hover.navbar__link--highlight {
            text-shadow: 0 0 5px $deep-sea-blue-25;
          }

          &:hover,
          &.navbar__link--active {
            color: $deep-sea-blue;
            background-color: $white;
          }

          @include break('small') {
            padding: 1em 2.55em;
            width: calc(100% - 5.1em);
          }
        }
      }
    }
  }

  @include break('medium') {
    display: block;
    padding: 0;
    margin: 0;
    align-self: center;

    .navbar__menu {
      flex-direction: column;
      background-color: $pale-grey;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
      max-height: 0;
      overflow: hidden;

      .header--fixed & {
        box-shadow: none;
      }

      &.navbar__menu--active {
        max-height: 600px;
      }
    }
  }

}