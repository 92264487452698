@mixin content {
  display: flex;
  flex-direction: column;
  align-items:center;
  width: calc(100% - 7.1em);
  padding: 4.5em 3.55em;

  @include break('small') {
    width: calc(100% - 5.1em);
    padding: 4.5em 2.55em;
  }

  h2 {
    font-weight: 600;
    font-size: 250%;
    line-height: 1em;
    letter-spacing: 1px;
    color: $deep-sea-blue;
    text-transform: uppercase;
    text-align: center;
    width: 100%;

    &:after {
      content: ' ';
      display: block;
      width: 1.5em;
      height: 3px;
      background-color: $deep-sea-blue;
      margin: 1em auto 0;
    }
  }

  h3 {
    color: $greyish-brown;
  }
}