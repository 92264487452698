@import 'elements/content';

.terms {
  @include content;
  padding-top: 10em;

  h3 {
    font-size: 100%;
    align-self: flex-start;
    margin: 1em 0 0;
  }

  h4 {
    font-size: 100%;
    align-self: flex-start;
    font-style: italic;
    font-weight: 400;
    margin: 1em 0 0;
  }

  p {
    width: 100%;
    text-align: justify;
    margin-top: 0.5em;
  }

  ul, ol {
    align-self: flex-start;
    margin: 0.5em 0;
    max-width: 100%;
  }

  &__list--numbered {
    max-width: 100%;
    margin: 0;
    padding: 0;

    ol {
      list-style-type: none;
      counter-reset: item;
      padding: 0;

      & > li {
        display: table;
        counter-increment: item;

        &:before {
          content: counters(item, ".") ".";
          display: table-cell;
          padding-right: 1em;
        }
      }
    }

    li ol > li {
      margin: 0;

      &:before {
        content: counters(item, ".") " ";
      }
    }
  }

  a {
    color: $deep-sea-blue;

    &:hover {
      color: $perrywinkle;
    }
  }

  @include break('small') {
    padding-top: 9em;
  }

  .underline {
    text-decoration: underline;
  }

  &__dateline {
    align-self: flex-start;
    font-style: italic;
    font-size: 80%;
  }

  &__address {
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    padding-left: 3em;
    margin-bottom: 2em;
  }

  &__subset {
    align-self: flex-start;
    padding-left: 1em;
    border-left: 3px solid $deep-sea-blue;
  }
}
