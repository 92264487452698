.header {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  width: 100%;

  &__container {
    display: flex;
    justify-content: space-between;
    align-content: center;
    padding: 1.25em 3.55em;
    width: calc(100% - 7.1em);
    overflow: hidden;

    @include break('small') {
      padding: 1.25em 2.55em;
      width: calc(100% - 5.1em);
    }
  }

  &__logo {
    display: flex;

    a {
      display: flex;
      align-self: center;

      img {
        display: block;
        border: none;
        outline: none;
      }

      @include break('small') {
        padding-right: 1em;

        img {
          width: 100%;
          min-width: 200px;

          @include break('x-small') {
            min-width: 180px;
          }
        }
      }
    }
  }

  &--forced {
    background-color: $deep-sea-blue;
  }

  &--fixed {
    position: fixed;
    background-color: $deep-sea-blue;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  }

  @include break('medium') {
    flex-direction: column;
  }
}