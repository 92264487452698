.sections {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  &__content {
    display: none;
    align-self: center;
    width: 90%;
    margin: 1.5em 0;

    a {
      color: $deep-sea-blue;
      font-weight: 600;

      &:hover {
        color: $perrywinkle;
      }
    }
  }

  &__content--active {
    display: inline;

    @include break('x-small') {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  &__3col {
    display: flex;
    flex-direction: row;
    align-items: center;

    @include break('small') {
      flex-direction: column;
    }

    div {
      align-self: flex-start;
      margin: 0 1em;

      @include break('small') {
        width: 100%;
        margin: 0.5em;

        img {
          float: left;
          margin: 0 1em 1em 0;
        }
      }

      @include break('x-small') {
        display: flex;
        flex: 0 1 auto;
        flex-direction: column;
        align-items: center;

        p {
          width: 100%;
        }

        img {
          float: none;
          margin: 0;
        }
      }
    }
  }

  &__nav {

    ul {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: stretch;
      margin: 0 auto;
      padding: 0;
      border: 1px solid $pale-grey;
      border-radius: 4px;

      @include break('small') {
        flex-direction: column;
      }

      li {
        flex: 0 1 auto;
        justify-content: center;
        align-items: center;
        width: 100%;
        list-style-type: none;
        border-left: 1px solid $pale-grey;

        @include break('small') {
          border-left: none;
          border-top: 1px solid $pale-grey;
        }

        &:first-child {
          border-left: none;

          @include break('small') {
            border-top: none;
          }
        }

        a {
          display: flex;
          justify-content: center;
          align-items: center;
          height: calc(100% - 1em);
          font-size: 112%;
          font-weight: 400;
          text-decoration: none;
          padding: 0.5em 4em;
          color: $greyish-brown;
          text-align: center;

          &:hover,
          &.sections__nav--active {
            background-color: $perrywinkle;
            color: $white;
          }
        }
      }
    }
  }
}