.footer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1em 3.55em 0;
  background-color: $deep-sea-blue;
  color: $white-50;
  font-size: 90%;
  font-weight: 300;

  @include break('small') {
    padding: 1em 2.55em 0;

    &__legal {
      width: 80%;
      display: flex;
      justify-content: center;
    }
  }

  &__container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    @include break('small') {
      flex-direction: column;
    }
  }

  .legal__link {
    margin-left: 1em;
    font-size: 90%;
    text-decoration: none;
    color: $white-50;

    @include break('small') {
      margin: 0.5em 0.5em 2em;
    }

    &:hover {
      color: $perrywinkle;
      text-decoration: underline;
    }
  }

  &:before {
    content: " ";
    display: block;
    margin: 1em auto 0;
    width: 100%;
    height: 1px;
    background-color: $white-50;
  }
}