@import 'elements/content';
@import 'elements/button';

.hero {
  @include content;
  justify-content: center;
  height: 700px;
  background-image: $hero-image;
  background-repeat: no-repeat;
  background-position: 50% 33%;
  background-size: cover;
  color: $white;

  @include break('small') {
    height: 500px;
  }

  &__lead {
    width: 100%;
    font-weight: 600;
    font-size: 350%;
    line-height: 1.5em;
    text-align: center;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);

    & > p {
      margin: 0;
      padding: 0 20%;
    }

    @include break('small') {
      font-size: 250%;
    }
  }

  @include button;
}