@import 'elements/content';
@import 'elements/button';

.suprafooter {
  @include content;
  background-color: $deep-sea-blue;
  color: $white;

  h1 {
    width: 100%;
    font-weight: 600;
    font-size: 200%;
    line-height: 1.5em;
    text-align: center;
  }

  @include button;
}

#clearnav {
  content: ' ';
  display: block;
  padding-top: 5.5em;
  margin-top: -5.5em;
  visibility: hidden;

  @include break('small') {
    padding-top: 4.5em;
    margin-top: -4.5em;
  }
}